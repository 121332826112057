/* eslint-disable no-undef */
<template>
  <b-overlay :show="loading" rounded="sm">
    <b-container class="campaign-detail my-2 my-md-4">
      <div class="row campaign-brief py-2 py-sm-3 py-lg-4" style="position: relative;">
        <div class="d-flex col-md-12 col-lg-12 py-1 align-items-center">
          <div class="campaign-image">
            <b-img :src="detailData.image && detailData.image.length > 0 ? detailData.image[0] : ''" class="camImg" />
          </div>
          <div class="ml-3 flex-1">
            <span v-if="detailData.commission_model === 1" class="freebies-tag">Crowdtesting</span>
            <span v-if="detailData.commission_model === 2" class="freebies-tag">Fixed Price</span>
            <span v-if="detailData.commission_model === 3" class="freebies-tag">Fixed price + Commission</span>
            <span v-if="detailData.commission_model === 4" class="freebies-tag">Crowdtesting + Commission</span>
            <a class="campaign-title d-block" :href="detailData.product_url" target="_blank">{{
              detailData.kol_title ? detailData.kol_title : detailData.amazon_title
            }}</a>
            <div class="campaign-tags pt-1 pb-1 pt-lg-2 pb-lg-2">
              <span v-for="item in detailData.tag" :key="item.name" class="yellow-tag mr-2">{{ item }}</span>
            </div>
            <div class="platform-area xs-none mb-1">
              <span class="label mr-2">Expected Release Platform: </span>
              <b-img v-for="platform in detailData.platform" :key="platform"
                style="width: 24px;height: 24px;margin-right: 8px;"
                :src="require(`@/assets/images/${platform.toLowerCase()}.png`)" fluid :alt="platform" />
            </div>
            <div class="category-area xs-none mb-0 mb-md-1">
              <!-- <span class="label">Category: </span><span class="text"></span> -->
              <span v-if="detailData.price_max && [2, 3].includes(detailData.commission_model)"
                style="margin-right: 24px;">
                <span class="label">Fixed Price: </span>
                <span class="text" style="color: #FFB426;">${{ `${detailData.price_min} - ${detailData.price_max}`
                  }}</span>
              </span>
              <span v-if="detailData.price_message && detailData.price_message !== '0.00'" style="margin-right: 24px;">
                <span class="label">Product Price: </span>
                <span class="text" style="color: #FFB426;">{{ detailData.price_message }}</span>
              </span>
              <span v-if="detailData.percent">
                <span class="label">Commission: </span>
                <span class="text" style="color: #FFB426;">{{ detailData.percent }}%</span>
              </span>
            </div>
            <div v-if="!detailData.percent" class="product-url">
              <span class="label">Product Link: </span>
              <span class="text">
                <a id="copy-link-1" :href="detailData.product_url" target="_blank"
                  style="display: table-cell;max-width: 200px;" class="text-ep">{{ detailData.product_url }}</a>
                <SvgIcon icon-class="copy" style="cursor: pointer;" class="copy-icon-1" data-clipboard-action="copy"
                  data-clipboard-target="#copy-link-1" @click="copy(1)" />
              </span>
            </div>
            <div v-if="detailData.percent" class="tips mt-2 mt-md-0 d-inline-block">
              Attribution link will be provided once the application is approved.
            </div>
          </div>
        </div>
        <!--  -->
        <b-button v-if="canApply" variant="primary" :disabled="detailData.apply_status === 2" class="apply-btn"
          @click="handleApply(detailData)">Apply Now</b-button>
      </div>
      <div class="xl">
        <div class="category-area actBox border-right-1">
          <p>
            <span class="label">Target Area:</span>
            <span class="text">
              <b-img v-if="detailData.site" :src="require(`@/icons/country/${detailData.site.toLowerCase()}.png`)"
                style="vertical-align: middle;width: 16px;height: 11px;transform: scale(1.3);" />
            </span>
          </p>
          <p v-if="detailData.kol_num">
            <span class="label">Collaborations Needed:</span>
            <span class="text">{{ detailData.kol_num }}</span>
          </p>
          <p v-if="detailData.contact_email">
            <span class="label">Contact us:</span>
            <span class="text" style="color: #FFB426;">
              <span id="copy-link-4" style="display: inline-flex;max-width: 160px;" class="text-ep">{{
                detailData.contact_email
              }}</span>
              <SvgIcon icon-class="copy" style="cursor: pointer;margin-left: 5px;" class="copy-icon-4"
                data-clipboard-action="copy" data-clipboard-target="#copy-link-4" @click="copy(4)" />
            </span>
          </p>
        </div>
        <div class="category-area actBox border-right-1">
          <p>
            <span class="label">Brand:</span><span class="text" style="color: #FFB426;cursor: pointer;"
              @click="jumpBrandDetail">{{ detailData.brand_name }}</span>
          </p>
          <p>
            <span class="label align-middle">Category:</span>
            <span class="d-inline-block align-middle text text-ep" style="width: 200px;" :title="detailData.category">{{
              detailData.category
            }}</span>
          </p>
        </div>
        <div class="category-area actBox">
          <p>
            <span class="label">Start and End Date:</span><span class="text ">{{
              detailData.pdt_start_time ? detailData.pdt_start_time.split(' ')[0] : ''
            }} ~ {{
                detailData.pdt_end_time ? detailData.pdt_end_time.split(' ')[0] : ''
              }}</span>
          </p>
          <p>
            <span class="label">Expected Release Date:</span><span v-if="detailData.pdt_expected_time" class="text ">{{
              detailData.pdt_expected_time
            }} ~ {{ detailData.pdt_expected_end_time }}</span>
          </p>
        </div>
      </div>
      <div class="md">
        <div class="category-area actBox border-right-1">
          <p>
            <span class="label">Target Area:</span>
            <span class="text ">
              <b-img v-if="detailData.site" :src="require(`@/icons/country/${detailData.site.toLowerCase()}.png`)"
                style="vertical-align: middle;width: 16px;height: 11px;transform: scale(1.3);" />
            </span>
          </p>
          <p v-if="detailData.kol_num">
            <span class="label">Collaborations Needed:</span>
            <span class="text">{{ detailData.kol_num }}</span>
          </p>
          <p>
            <span class="label">Start and End Date:</span>
            <span class="text ">{{ detailData.pdt_start_time ? detailData.pdt_start_time.split(' ')[0] : '' }} ~ {{
              detailData.pdt_end_time ? detailData.pdt_end_time.split(' ')[0] : ''
            }}</span>
          </p>
          <p v-if="detailData.contact_email">
            <span class="label">Contact us:</span>
            <span class="text" style="color: #FFB426;">
              <span id="copy-link-5" style="display: inline-flex;max-width: 160px;" class="text-ep">{{
                detailData.contact_email
              }}</span>
              <SvgIcon icon-class="copy" style="cursor: pointer;margin-left: 5px;" class="copy-icon-5"
                data-clipboard-action="copy" data-clipboard-target="#copy-link-5" @click="copy(5)" />
            </span>
          </p>
        </div>
        <div class="category-area actBox">
          <p>
            <span class="label">Brand:</span>
            <span class="text" style="color: #FFB426;cursor: pointer;" @click="jumpBrandDetail">{{
              detailData.brand_name
            }}</span>
          </p>
          <p>
            <span class="label align-middle">Category:</span>
            <span class="d-inline-block align-middle text text-ep" style="width: 200px;" :title="detailData.category">{{
              detailData.category
            }}</span>
          </p>
          <p>
            <span class="label">Expected Release Date:</span>
            <span v-if="detailData.pdt_expected_time" class="text ">{{ detailData.pdt_expected_time }} ~ {{
              detailData.pdt_expected_end_time
            }}</span>
          </p>
        </div>
      </div>
      <div class="sm">
        <div class="category-area actBox">
          <p v-if="!detailData.percent">
            <span class="label">Product Link: </span>
            <span class="text">
              <a id="copy-link-2" :href="detailData.product_url" target="_blank"
                style="display: table-cell;max-width: 200px;" class="text-ep">{{ detailData.product_url }}</a>
              <SvgIcon icon-class="copy" style="cursor: pointer;" class="copy-icon-2" data-clipboard-action="copy"
                data-clipboard-target="#copy-link-2" @click="copy(2)" />
            </span>
          </p>
          <p>
            <span class="label">Target Area:</span>
            <span class="text ">
              <b-img v-if="detailData.site" :src="require(`@/icons/country/${detailData.site.toLowerCase()}.png`)"
                style="vertical-align: middle;width: 16px;height: 11px;transform: scale(1.3);" />
            </span>
          </p>
          <p v-if="detailData.kol_num">
            <span class="label">Collaborations Needed:</span>
            <span class="text ">{{ detailData.kol_num }}</span>
          </p>
          <p>
            <span class="label">Start and End Date:</span>
            <span class="text ">{{ detailData.pdt_start_time ? detailData.pdt_start_time.split(' ')[0] : '' }} ~ {{
              detailData.pdt_end_time ? detailData.pdt_end_time.split(' ')[0] : ''
            }}</span>
          </p>
          <p>
            <span class="label">Brand:</span>
            <span class="text" style="color: #FFB426;cursor: pointer;" @click="jumpBrandDetail">{{
              detailData.brand_name
            }}</span>
          </p>
          <p>
            <span class="label align-middle">Category:</span>
            <span class="d-inline-block align-middle text text-ep" style="width: 200px;" :title="detailData.category">{{
              detailData.category
            }}</span>
          </p>
          <p>
            <span class="label">Expected Release Date:</span>
            <span v-if="detailData.pdt_expected_time" class="text ">{{ detailData.pdt_expected_time }} ~ {{
              detailData.pdt_expected_end_time
            }}</span>
          </p>
          <p v-if="detailData.contact_email">
            <span class="label">Contact us:</span>
            <span class="text" style="color: #FFB426;">
              <span id="copy-link-6" style="display: inline-flex;max-width: 160px;" class="text-ep">{{
                detailData.contact_email
              }}</span>
              <SvgIcon icon-class="copy" style="cursor: pointer;margin-left: 5px;" class="copy-icon-6"
                data-clipboard-action="copy" data-clipboard-target="#copy-link-6" @click="copy(6)" />
            </span>
          </p>
        </div>
      </div>
      <div class="xs">
        <div class="category-area actBox">
          <p>
            <span class="label mr-2">Expected Release Platform: </span>
            <b-img v-for="platform in detailData.platform" :key="platform"
              style="width: 24px;height: 24px;margin-right: 8px;"
              :src="require(`@/assets/images/${platform.toLowerCase()}.png`)" fluid :alt="platform" />
          </p>
          <p v-if="detailData.price_max && [2, 3].includes(detailData.commission_model)">
            <span class="label">Fixed Price: </span>
            <span class="text" style="color: #FFB426;">${{ `${detailData.price_min} - ${detailData.price_max}` }}</span>
          </p>
          <p v-if="detailData.price_message && detailData.price_message !== '0.00'">
            <span class="label">Product Price: </span>
            <span class="text" style="color: #FFB426;">{{ detailData.price_message }}</span>
          </p>
          <p v-if="detailData.percent">
            <span class="label">Commission: </span>
            <span class="text" style="color: #FFB426;">{{ detailData.percent }}%</span>
          </p>
          <p v-if="!detailData.percent">
            <span class="label">Product Link: </span>
            <span class="text" style="display: flex;">
              <a id="copy-link-3" :href="detailData.product_url" target="_blank"
                style="display: table-cell;max-width: 200px;" class="text-ep">{{ detailData.product_url }}</a>
              <SvgIcon icon-class="copy" style="cursor: pointer;" class="copy-icon-3" data-clipboard-action="copy"
                data-clipboard-target="#copy-link-3" @click="copy(3)" />
            </span>
          </p>
          <p>
            <span class="label">Target Area:</span>
            <span class="text mt-1">
              <b-img v-if="detailData.site" :src="require(`@/icons/country/${detailData.site.toLowerCase()}.png`)"
                style="vertical-align: middle;width: 16px;height: 11px;transform: scale(1.3);" />
            </span>
          </p>
          <p v-if="detailData.kol_num">
            <span class="label">Collaborations Needed:</span><span v-if="detailData.kol_num" class="text ">{{
              detailData.kol_num
            }}</span>
          </p>
          <p>
            <span class="label">Start and End Date:</span><span class="text ">{{
              detailData.pdt_start_time ? detailData.pdt_start_time.split(' ')[0] : ''
            }} ~ {{
                detailData.pdt_end_time ? detailData.pdt_end_time.split(' ')[0] : ''
              }}</span>
          </p>
          <p>
            <span class="label">Brand:</span><span class="text" style="color: #FFB426;cursor: pointer;"
              @click="jumpBrandDetail">{{ detailData.brand_name }}</span>
          </p>
          <p>
            <span class="label align-middle">Category:</span>
            <span class="d-inline-block align-middle text-right text text-ep" style="width: 200px;"
              :title="detailData.category">{{ detailData.category }}</span>
          </p>
          <p>
            <span class="label">Expected Release Date:</span><span v-if="detailData.pdt_expected_time" class="text ">{{
              detailData.pdt_expected_time
            }} ~ {{ detailData.pdt_expected_end_time }}</span>
          </p>
          <p v-if="detailData.contact_email">
            <span class="label">Contact us:</span>
            <span class="text" style="color: #FFB426;">
              <span id="copy-link-7" style="display: inline-flex;max-width: 160px;" class="text-ep">{{
                detailData.contact_email
              }}</span>
              <SvgIcon icon-class="copy" style="cursor: pointer;margin-left: 5px;" class="copy-icon-7"
                data-clipboard-action="copy" data-clipboard-target="#copy-link-7" @click="copy(7)" />
            </span>
          </p>
        </div>
      </div>
    </b-container>
    <b-container class="campaign-detail py-2 py-sm-3 py-lg-4 my-2 my-md-4">
      <div class="product-title">Brand Brief</div>
      <b-row class="brands-info d-sm-inline-flex flex-sm-nowrap">
        <div style="margin-right: 20px;">
          <b-img :src="brandInfo.image" class="brand-logo" />
        </div>
        <div>
          <div class="d-flex align-items-center d-sm-block">
            <div class="brand-content d-none d-sm-block">{{ brandInfo.brand_story }}</div>
          </div>
          <div class="d-sm-none">
            <div class="brand-content">{{ brandInfo.brand_story }}</div>
          </div>
          <div v-if="detailData.brief_detail && detailData.brief_detail.url"
            @click="handleBriefDetail(detailData.brief_detail)" style="cursor: pointer;color: #ffb426;">
            Brief Detail >
          </div>
        </div>

      </b-row>
    </b-container>
    <b-container class="campaign-detail py-2 py-sm-3 py-lg-4 my-2 my-md-4">
      <div class="product-title">Promotion Requirements</div>
      <b-row class="brands-info d-sm-inline-flex flex-sm-nowrap">
        <div class="product-brief px-2 py-1 py-md-2" style>
          <ul>
            <li v-for="(item, index) in detailData.suggestion" :key="index" style="margin: 7px 0;">
              {{ (index + 1) + '.' }}{{ item }}
            </li>
          </ul>
        </div>
      </b-row>
    </b-container>
    <b-container class="product-detail py-2 py-sm-3 py-lg-4 my-2 my-md-4">
      <div class="product-title">Product Details</div>
      <div class="product-brief row px-2 py-2 py-md-2">
        <div class="product-images col-sm-12 col-md-12 col-lg-5">
          <div class="swiper origin-images">
            <div class="swiper-wrapper">
              <div v-for="(item, index) in imageList" :key="index" class="product-origin-image swiper-slide"><img
                  :src="item"></div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="swiper-button-left"><img src="@/assets/images/left-arrow.png"></div>
            <div class="swiper thumb-images">
              <div class="swiper-wrapper">
                <div v-for="(item, index) in imageList" :key="'product' + index"
                  class="product-thumb-image swiper-slide">
                  <div :style="{ backgroundImage: 'url(' + item + ')' }" class="image" />
                </div>
              </div>
            </div>
            <div class="swiper-button-right"><img src="@/assets/images/right-arrow.png"></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-7 smallContent">
          <!-- <div class="product-title pt-2 pt-sm-0">{{ detailData.kol_title?detailData.kol_title:detailData.amazon_title }}</div> -->
          <div class="product-sub-title">{{ detailData.subtitle }}</div>
          <div class="grey-gap" />
          <div class="product-sub-title">Parameters</div>
          <div v-for="(item, index) in detailData.description" :key="index" style="margin-bottom: 16px;">
            <li class="text listType">{{ item }}</li>
          </div>
        </div>
      </div>
      <div class="product-title" v-if="referenceVideo.length > 0">
        Reference Video
      </div>
      <div class="product-brief px-2 py-1 py-md-2" v-if="referenceVideo.length > 0">
        <!-- <video width="640" height="360" controls v-for="item in referenceVideo" :key="item.product_asin_id"
          style="margin: 12px;">
          <source :src="item.url" type="video/mp4" />
          您的浏览器不支持视频标签。
        </video> -->
        <div class="videoContent">
          <div v-for="item in referenceVideo" :key="item.product_asin_id"
            :class="item.cover ? 'videoImg' : 'videoDefaultImg'" @click="gotoVideo(item)">
            <img class="video" :src="item.cover ? item.cover : require('@/assets/images/apply/default.png')" alt="">
            <img class="playImg" :src="require('@/assets/images/apply/play.png')" alt="">
          </div>
        </div>

      </div>
      <div class="product-title" v-if="contentIdea">Content Idea</div>
      <div class="product-brief px-2 py-1 py-md-2" v-if="contentIdea">
        <div class="col-sm-24 col-md-24 col-lg-24 smallContent">
          <b-form-textarea id="draft_content" v-model="contentIdea" rows="3" no-resize max-rows="8000" disabled
            style="background: #fff;border: none;margin-bottom: 20px;line-height: 24px;color:#333333;" />
          <!-- <div class="idea">
            {{ contentIdea }}
          </div> -->
        </div>
      </div>
      <div class="rulesBox">
        <div class="product-title">Rules of GotVoom</div>
        <div class="product-brief px-2 py-1 py-md-2">
          <ul>
            <li v-for="(item, index) in rules" :key="index">{{ (index + 1) + '.' }}{{ item }}</li>
          </ul>
        </div>
      </div>
    </b-container>
    <Apply v-if="showApply" :modal-show.sync="showApply" :is-pc="isPC" :event-this="this" :email="email"
      :detailData="detailData" />
    <ConfirmDialog v-if="showConfirmDialog" :modal-show.sync="showConfirmDialog" :confirm-is-main="false"
      content="Your shipping address doesn't match the required location of the campaign. Do you still want to apply?"
      @clickConfirm="handleConfirmApply" />
  </b-overlay>
</template>
<script>
import Clipboard from 'clipboard'
import Swiper, { Navigation, Thumbs } from 'swiper'
import 'swiper/swiper-bundle.css'
import { getCampaignsDetail, checkUserInfo } from '@/api/campaign'
import { getUserShippingInfo } from '@/api/user'
import { getBrandDetail } from '@/api/brands'
import Apply from './components/apply.vue'
import ConfirmDialog from './components/confirmDialog.vue'
export default {
  name: 'CampaignsDetail',
  components: {
    Apply,
    ConfirmDialog
  },
  data() {
    return {
      showConfirmDialog: false,
      alreadyConfirm: false,
      canApply: true,
      showApply: false,
      isPC: false,
      loading: false,
      detailData: {},
      thumbsSwiper: {},
      imagesSwiper: {},
      imageList: [],
      contentIdea: '',
      referenceVideo: [],
      merchantInfo: {},
      otherParam: {},
      token: null,
      rules: [
        'Please provide the correct identifying details, shipment address，payment method, and social media accounts.',
        "Do not delete or hide the post, your photo/video and caption must not mention or show any competitor's brand, logos, or products.",
        'Only submit original content that you either own or have created.',
        'Participating in an event is the same as granting Gotvoom/brand permission to use the content.',
        'To receive the cooperation fee, you must complete all tasks in this campaign. If you do not complete the campaign on time or if the draft does not meet the requirements, Gotvoom reserves the right not to pay the fee.',
        'If you have any problems during the campaign, you can directly contact the marketing manager by e-mail which is show on the campaign page; other platform problems the Gotvoom team can help you through support@gotvoom.com.'
      ],
      brandInfo: {},
      email: ''
    }
  },
  created() {
    this.token = localStorage.getItem('token')
    this.token && this.getShippingInfo()
  },
  mounted() {
    this.isPC = document.body.clientWidth >= 1200
    window.onresize = () => {
      this.pageResize()
    }
    this.getCampaignsDetail()
    this.thumbsSwiper = new Swiper('.thumb-images', {
      spaceBetween: 10,
      slidesPerView: 4,
      modules: [Navigation],
      navigation: {
        nextEl: '.swiper-button-right',
        prevEl: '.swiper-button-left'
      },
      imagesReady(s) {
        console.log(s)
      }
    })
    this.imagesSwiper = new Swiper('.origin-images', {
      spaceBetween: 1,
      modules: [Thumbs],
      thumbs: {
        swiper: this.thumbsSwiper
      }
    })
  },
  methods: {
    gotoVideo(item) {
      window.open(item.url)
    },
    handleBriefDetail(brief_detail) {
      const linkNode = document.createElement('a')
      // a标签的download属性规定下载文件的名称
      linkNode.download = brief_detail.name
      linkNode.style.display = 'none'
      linkNode.href = brief_detail.url // 生成一个Blob URL
      document.body.appendChild(linkNode)
      linkNode.click()
      URL.revokeObjectURL(linkNode.href) // 释放URL 对象
      document.body.removeChild(linkNode)
    },
    getBrandDetail(id) {
      const param = {
        id: id
      }
      getBrandDetail(param)
        .then((res) => {
          if (res.data) {
            this.brandInfo = res.data
          }
        })
    },
    jumpBrandDetail() {
      this.$router.push({
        path: `/brands/detail`,
        query: {
          id: this.detailData.brand_id
        }
      })
    },
    copy(index) {
      var clipboard = new Clipboard(`.copy-icon-${index}`)
      clipboard.on('success', (e) => {
        this.$root.$emit('showToast', {
          show: true,
          type: 'success',
          massage: 'Copy successfully！',
          position: 'top',
          duration: 1000
        })
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        clipboard.destroy()
      })
    },
    pageResize() {
      this.isPC = document.body.clientWidth > 1200
    },
    filterCountry(code) {
      const countryOptions = this.$store.state.country.countryOptions
      const currentCountry = countryOptions.find((country) => {
        return country.code === code
      })
      if (currentCountry) {
        return currentCountry.name
      } else {
        return code
      }
    },
    getCampaignsDetail() {
      this.loading = true
      getCampaignsDetail({
        token: this.token || '',
        campaign_id: Number(this.$route.query.id)
      })
        .then((res) => {
          const pdt_time = new Date(res.data.pdt_end_time).getTime()
          const current_time = new Date().getTime()
          if (current_time > pdt_time) {
            this.canApply = false
          }
          res.data.category = res.data.category.join(',')
          this.getBrandDetail(res.data.brand_id)
          this.detailData = res.data
          this.imageList = res.data.image
          this.contentIdea = res.data.content_idea
          this.referenceVideo = res.data.reference_video || []
        })
        .finally(() => {
          this.loading = false
        })
    },
    getShippingInfo() {
      return getUserShippingInfo({ token: this.token }).then((res) => {
        this.otherParam.campaign_apply_address = res.data
      })
    },
    checkUserBaseInfo() {
      this.loading = true
      return checkUserInfo({
        token: this.token
      })
        .then((res) => {
          // res.data.shippingCheck = false
          const userTabList = {
            channelCheck: 'Channel',
            shippingCheck: 'ShippingAndPayment',
            paymentCheck: 'ShippingAndPayment'
          }
          // for (const [key, val] of Object.entries(res.data)) {
          //   if (val === false) {
          //     this.$router.push({
          //       path: '/user',
          //       query: {
          //         currentNav: userTabList[key]
          //       }
          //     })
          //     return false
          //   }
          // }
          this.otherParam.apply_num = res.data.apply_num
          this.otherParam.platform_info = res.data.platform_info
          this.email = res.data.email
          return true
        })
        .finally(() => {
          this.loading = false
        })
    },
    async handleApply(productInfo) {
      if (this.token) {
        // 点击Apply埋点
        if (window.gtag) {
          window.gtag('event', 'apply_click', {
            event_label: '点击申请'
          })
        }
        let callback = true
        //检查国家与红人当前信息的地址是否一致
        callback = await this.checkUserBaseInfo()
        if (callback && !this.alreadyConfirm && this.otherParam.campaign_apply_address.country_short && this.otherParam.campaign_apply_address.country_short !== this.detailData.site) {
          this.showConfirmDialog = true
          callback = false
        }
        if (!callback) {
          return
        }
        this.alreadyConfirm = false
        const param = { ...this.otherParam, ...productInfo }
        sessionStorage.setItem('applyInfo', JSON.stringify(param))
        if (this.isPC) {
          this.showApply = true
        } else {
          this.$router.push({
            path: '/campaigns/apply'
          })
        }
      } else {
        this.$router.push({
          path: '/login/loginModel'
        })
      }
    },
    handleConfirmApply() {
      this.alreadyConfirm = true
      this.handleApply(this.detailData)
    }
  }
}
</script>
<style lang="scss" scoped>
.listType {
  padding-left: 12px;
  text-indent: -21px;
  list-style: disc;
  word-break: break-word;
}

.xs-none {
  margin-bottom: 12px;
}

.border-right-1 {
  border-right: 1px solid #e6e6e6;
  margin-right: 20px;
}

.brandsBox {
  width: 100%;
  font-size: 14px;
  font-family: Arial;
  font-weight: 400;
  line-height: 16px;
  color: #999999;
  position: relative;
  display: flex !important;
  align-items: center;
  padding-top: 14px !important;
  margin-top: 12px;
  padding-left: 0;

  span {
    margin-right: 12px;
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }
}

::v-deep.swiper {
  width: 100%;
}

.flex-1 {
  flex: 1;
}

.swiper-wrapper {
  align-items: center;
}

.apply-btn {
  position: fixed;
  bottom: 30px;
  z-index: 1000;
  // padding: 8px 64px;
  border: 1px solid #FCC03E;
  border-radius: 6px;
  background: #FCC03E;
  color: #333333;
  font-size: 16px;
  font-family: Arial;
  font-weight: bold;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
}

.grey-gap {
  height: 2px;
  border: 1px solid #f7f7f8;
}

.yellow-tag {
  display: inline-block;
  min-width: 48px;
  text-align: center;
  line-height: 12px;
  padding: 4px 7px;
  margin-right: 8px;
  height: 24px;
  background: #F5F5F5;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  border: 1px solid #D9D9D9;
  font-size: 12px;
  color: #666666;
}

.yellow-stick {
  width: 3px;
  height: 100%;
  border: 1px solid #FCC03E;
  background: #FCC03E;
  margin-right: 8px;
}

.label {
  font-size: 14px;
  font-family: Arial;
  font-weight: 400;
  color: #999999;
  line-height: 16px;
}

.text {
  font-size: 14px;
  font-family: Arial;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
  margin-left: 12px;

  a {
    color: #ffb426;
    font-weight: 400;
  }
}

.swiper-button-left {
  cursor: pointer;
  margin-right: 8px;
}

.swiper-button-right {
  cursor: pointer;
  margin-left: 8px;
}

.campaign-detail {
  background: white;
  border-radius: 8px;
  padding: 32px 46px;
  position: relative;

  .product-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 46px;
    color: #333333;
    background: url(/img/detailBg.e59ebeef.png) no-repeat;
    height: 46px;
    width: 100%;
    padding: 0 12px;
  }

  .campaign-brief {
    .campaign-image {
      width: 80px;
      height: 80px;
      overflow: hidden;
      position: relative;
      border: 1px solid #f0f0f0;
      border-radius: 8px;

      .camImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .campaign-title {
      font-size: 16px;
      font-family: Arial;
      font-weight: bold;
      line-height: 24px;
      color: #333333;
      text-decoration: underline;
      cursor: pointer;
      overflow: hidden;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .freebies-tag {
      height: 24px;
      background: #FCC03E;
      border-radius: 12px 12px 12px 0px;
      font-size: 12px;
      color: #333333;
      padding: 0 12px;
      line-height: 24px;
      display: inline-block;
    }

    .platform-area {
      display: flex;
      align-items: center;
      // margin-bottom: 6px;
    }

    .tips {
      font-size: 12px;
      color: #ff5124;
      line-height: 18px;
      padding: 5px 9px;
      background: #f7f7f8;
    }
  }

  .brands-info {
    width: 100%;
    color: #333;
    background-color: #fff;
    padding: 0 17px;
    border-radius: 8px;

    .brand-logo {
      width: 140px;
      height: 140px;
      border-radius: 8px;
      overflow: hidden;
    }

    .brand-title {
      font-size: 24px;
      font-weight: bold;
      line-height: 42px;
    }

    .brand-content {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.product-detail {
  background: white;
  border-radius: 8px;
  padding: 32px 46px;

  .product-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 46px;
    color: #333333;
    background: url('../../assets/images/apply/detailBg.png') no-repeat;
    height: 46px;
    width: 100%;
    padding: 0 12px;
    margin-bottom: 12px;
  }

  .rulesBox {
    background: url('../../assets/images/apply/ruleBg.png') no-repeat;
  }

  .product-brief {
    ul {
      li {
        font-size: 14px;
        font-family: Arial;
        font-weight: 400;
        line-height: 26px;
        color: #333333;
        word-break: break-word;
      }
    }

    .swiper {
      .product-origin-image {
        width: 400px;
        height: 438px;
        margin-bottom: 24px;
      }

      .swiper-slide {
        border-radius: 12px;
        border: 1px solid #f0f0f0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 12px;
        }
      }
    }

    .swiper-thumbs {
      .swiper-slide {
        background: #f2f2f2;
        border-radius: 6px;
        overflow: hidden;

        .image {
          width: 100%;
          height: 0;
          padding-top: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    .product-title {
      font-size: 20px;
      font-family: Arial;
      font-weight: bold;
      line-height: 30px;
      color: #333333;
      overflow: hidden;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .product-sub-title {
      font-size: 16px;
      font-family: Arial;
      font-weight: bold;
      line-height: 24px;
      color: #333333;
    }

    .videoContent {
      display: flex;
      flex-wrap: wrap;

      .videoImg {
        width: 160px;
        height: 284px;
        margin: 0 32px 32px 0;
        border-radius: 13px 13px 13px 13px;
        cursor: pointer;
        background: #1C1716;
        border-radius: 12px 12px 12px 12px;

        position: relative;

        .video {
          width: 160px;
          height: 284px;
          object-fit: cover;
          opacity: 0.4;
        }

      }

      .playImg {
        position: absolute;
        left: 35%;
        top: 40%;
        z-index: 10;
        width: 46px;
        height: 46px;
      }

      .videoDefaultImg {
        width: 160px;
        height: 284px;
        margin: 0 32px 32px 0;
        border: 1px solid #707070;
        border-radius: 13px 13px 13px 13px;
        background: #1C1716;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;

        .video {
          width: 110px;
          height: 66px;
        }
      }

    }
  }
}

@media (max-width: 1200px) {
  .apply-btn {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .smallContent {
    margin-bottom: 70px;
    margin-top: 32px;
  }

  .swiper {
    .product-origin-image {
      height: 672px !important;
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .smallContent {
    margin-bottom: 70px;
    margin-top: 32px;
  }

  .swiper {
    .product-origin-image {
      height: 494px !important;
    }
  }
}

@media (max-width: 576px) {
  .apply-btn {
    width: 50%;
  }

  .campaign-brief {
    border-bottom: 1px solid #f0f0f0;

    .campaign-tags {
      margin-bottom: -8px;
    }
  }

  .xs {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .actBox {
      display: flex;
      flex-direction: column;
      flex: 1;

      p {
        margin: 2px 0;

        .text,
        img {
          float: right;
        }
      }
    }
  }

  .lg,
  .xl,
  .md,
  .sm,
  .xs-none,
  .product-url {
    display: none !important;
  }

  .smallContent {
    margin-bottom: 70px;
    margin-top: 32px;
  }

  .smallBtm {
    margin: 4px 0;
  }

  .swiper {
    .product-origin-image {
      max-height: 344px !important;
    }
  }
}

@media (min-width: 576px) {
  .campaign-brief {
    border-bottom: none;
  }

  .sm {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .actBox {
      display: flex;
      flex-direction: column;
      flex: 1;

      p {
        margin: 8px 0;
      }
    }
  }

  .lg,
  .xl,
  .md,
  .xs,
  .product-url {
    display: none;
  }

  .xs-none {
    display: block;
  }

  .yellow-tag {
    margin-bottom: 3px;
  }

  .campaign-detail {
    .campaign-brief {
      .campaign-image {
        width: 120px;
        height: 120px;
        border-radius: 8px;
      }
    }
  }
}

@media (min-width: 768px) {
  .md {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .actBox {
      display: flex;
      flex-direction: column;
      flex: 1;

      p {
        margin: 8px 0;
      }
    }
  }

  .lg,
  .xl,
  .sm,
  .xs {
    display: none;
  }

  .product-url {
    display: block;
  }

  .label {
    font-size: 14px;
    line-height: 24px;
  }

  .text {
    font-size: 14px;
    line-height: 24px;
  }

  .campaign-detail {
    .campaign-brief {
      .campaign-image {
        width: 180px;
        height: 180px;
      }
    }
  }
}

@media (min-width: 992px) {
  .xl {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .actBox {
      display: flex;
      flex-direction: column;
      flex: 1;

      p {
        margin: 8px 0;
      }
    }
  }

  .lg,
  .md,
  .sm,
  .mdLine,
  .xs {
    display: none;
  }

  .campaign-title {
    font-size: 18px !important;
    line-height: 27px !important;
  }

  .product-detail {
    .product-brief {
      .product-title {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .xl {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .actBox {
      display: flex;
      flex-direction: column;
      flex: 1;

      p {
        margin: 8px 0;
      }
    }
  }

  .lg,
  .md,
  .sm,
  .xs {
    display: none;
  }

  .mdLine {
    display: block;
  }

  .apply-btn {
    position: absolute;
    left: auto;
    right: 16px;
  }
}

.idea {
  font-size: 14px;
  font-family: Arial;
  font-weight: 400;
  line-height: 26px;
  color: #333333;
  word-break: break-word;
}

::v-deep .form-control {
  overflow-y: auto !important;
}
</style>
