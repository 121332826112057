









import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class ConfirmDialog extends Vue {
  @Prop({ default: false }) modalShow!: boolean
  @Prop({ default: '' }) content!: string
  @Prop({ default: true }) confirmIsMain!: boolean
  get visible() {
    return this.modalShow
  }
  set visible(newVal) {
    this.$emit('update:modalShow', newVal)
  }
  handleConfirm() {
    this.$emit('clickConfirm')
    this.handleClose()
  }
  handleClose() {
    this.visible = false
  }
}
