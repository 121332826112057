import request from '../utils/request'

interface brandList {
  page: number
  per_page: number
  brand_name: string
  merchant_name: string
}
export function getBrandList(params: brandList): Promise<any> {
  return request({
    url: '/v1/brand/get-list',
    method: 'get',
    params
  })
}

interface brandDetail {
  id: number
}
export function getBrandDetail(params: brandDetail): Promise<any> {
  return request({
    url: '/v1/brand/detail',
    method: 'get',
    params
  })
}
